$black: #111111;
$white: #ffffff;
$white1: #f0f6ff;
$white2: #f0f3f6;
$white3: #e6effc;
$white4: #d9e2f2;
$lightBlue: #a6abc4;
$green: #208938;
$lightGreen: #629eb3;
$lightGreen1: #e2f0db;
$lightGreen2: #388ca5;
$lightGreen3: #2d86a0;
$lightGreen4: #cce4ed;
$lightGreen5: #4d93a5;
$red: #c92332;
$grey: #74808d;
$grey1: #adb6b8;
$grey2: #a4a8b0;
$grey3: #9ea1ac;
$grey4: #959ca5;
$grey5: #a4a4a4;
$grey6: #767676;
$grey7: #cbcecd;
$lightGrey: #d9d8d4;
$darkGrey: #85858f;
$purple: #645dbe;
