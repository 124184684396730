@import "../../scss/colors.scss";

.__header {
	background: $white;

	.__navBar {
		display: flex;
		flex-direction: row;
		list-style-type: none;
		margin: 0;
		padding: 0;

		> li {
			margin: 1em;

			> a {
				text-decoration: none;
				color: $lightGreen2;
				font-weight: bold;
			}

			.active {
				color: $lightBlue;
				font-weight: normal;
			}
		}
	}
}
